import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const ThanksPageTemplate = ({ image, title, message }) => {
  return (
    <div>
      <div className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                'rgb(84, 96, 227) 0.5rem 0px 0px, rgb(84, 96, 227) -0.5rem 0px 0px',
              backgroundColor: 'rgb(84, 96, 227)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {title}
          </h1>
        </div>
      </div>      
	  <section className="section">
        <div className="container">
          <div className="content">
            <p>{message}</p>
          </div>
        </div>
      </section>
    </div>    
  )
}

ThanksPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
}

const ThanksPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ThanksPageTemplate
        image={frontmatter.image}      
        title={post.frontmatter.title}
        message={post.frontmatter.message}
      />
    </Layout>
  )
}

ThanksPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ThanksPage

export const ThanksPageQuery = graphql`
  query ThanksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        message
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }        
      }
    }
  }
`
